import PropTypes from 'prop-types';

import sun from '../../assets/sun.png';

function Sun({ numOfSun }) {
  switch (numOfSun) {
    case 5:
      return (
        <div>
          <img src={sun} alt="sun" />
          <img src={sun} alt="sun" />
          <img src={sun} alt="sun" />
          <img src={sun} alt="sun" />
          <img src={sun} alt="sun" />
        </div>
      );
    case 4:
      return (
        <div>
          <img src={sun} alt="sun" />
          <img src={sun} alt="sun" />
          <img src={sun} alt="sun" />
          <img src={sun} alt="sun" />
        </div>
      );
    case 3:
      return (
        <div>
          <img src={sun} alt="sun" />
          <img src={sun} alt="sun" />
          <img src={sun} alt="sun" />
        </div>
      );
    case 2:
      return (
        <div>
          <img src={sun} alt="sun" />
          <img src={sun} alt="sun" />
        </div>
      );
    case 1:
      return (
        <div>
          <img src={sun} alt="sun" />
        </div>
      );

    default:
      return <h1>해당 정보가 없습니다.</h1>;
  }
}

Sun.propTypes = {
  numOfSun: PropTypes.number,
};

export default Sun;
