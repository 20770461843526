import styles from './mainPage.module.scss';
import SecondaryButton from '../../components/Buttons/SecondButton';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../redux/slice';
import { useNavigate } from 'react-router-dom';
import { removeCookie } from '../../util/cookie';

import plant1 from '../../assets/1.png';

import Carousel from '../../components/Carousel';
import Rank from '../../components/Rank';

const DUMMY_DATA = [
  {
    plantName: '스킨답서스',
    plantNameEng: 'Epipremnum aureum',
    numOfSun: 3,
    numOfWater: 5,
    nanedo: '쉬움',
    ondo: '20~28도',
    talk: '다산과 부자',
  },
  {
    plantName: '테이블야자',
    plantNameEng: 'Parlour Palm',
    numOfSun: 1,
    numOfWater: 2,
    nanedo: '쉬움',
    ondo: '21~29도',
    talk: '행운',
  },
  {
    plantName: '필레아페페',
    plantNameEng: 'pilea peperomioides',
    numOfSun: 4,
    numOfWater: 4,
    nanedo: '보통',
    ondo: '20~26도',
    talk: '금전운과 행운',
  },
];

function MainPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);

  const fallBackUI = <p className={styles.fallback}>로그인 후 식물을 등록해주세요.</p>;

  return (
    <div className={styles.mainPage}>
      <div className={styles.headBlock}>
        <div className={styles.buttonBlock}>
          <SecondaryButton
            type="button"
            onClick={() => {
              if (isAuthenticated) {
                removeCookie('token');
                dispatch(logout());
                navigate('/login');
                return;
              }
              navigate('/signup');
            }}
          >
            {isAuthenticated ? 'Log out' : 'Sign Up'}
          </SecondaryButton>
        </div>
        <div className={styles.titleBlock}>
          <p className={styles.title}>
            <span className={styles['title--small']}>We&apos;re</span>
            <br /> Gardeners.
          </p>
        </div>
        <div className={styles.imgWrapper}>
          <img src={plant1} className={styles.plant1Img} alt="plant" />
        </div>
        <div className={styles.buttonContainer}>
          <button
            className={styles.menu}
            type="button"
            onClick={() => {
              navigate('/');
            }}
          >
            HOME
          </button>
          <button
            className={styles.menu}
            type="button"
            onClick={() => {
              navigate('/myPlant');
            }}
          >
            PLANT
          </button>
          <button
            className={styles.menu}
            type="button"
            onClick={() => {
              navigate('/board');
            }}
          >
            BOARD
          </button>
        </div>
      </div>

      <div className={styles.plantBox}>
        <div className={styles.carousel}>{isAuthenticated ? <Carousel /> : fallBackUI}</div>
      </div>
      <div className={styles.plantRank}>
        {/* <Rank numOfSun={3} numOfWater={4} index={1} />
        <Rank numOfSun={3} numOfWater={4} index={2} />
        <Rank numOfSun={3} numOfWater={4} index={3} /> */}
        {DUMMY_DATA.map((plantItem, index) => {
          const key = `${index}-${plantItem.plantName}`;
          return <Rank {...plantItem} index={index} key={key} />;
        })}
      </div>
    </div>
  );
}

export default MainPage;
