import Axios from '../../util/axios';

export default {
  /**
   *  회원가입 API
   * @param {string} id 사용자 아이디
   * @param {string} password 사용자 패스워드
   * @returns Axios
   */
  signUp(id, password) {
    return Axios({
      url: '/signup',
      method: 'post',
      data: {
        id,
        password,
      },
    });
  },

  signIn(id, password) {
    return Axios({
      url: '/login',
      method: 'post',
      data: {
        id,
        password,
      },
    });
  },

  viewSinglePlant(number) {
    return Axios({
      url: `/plant/${number}`,
      method: 'get',
    });
  },

  viewRank() {
    return Axios({
      url: '/',
      method: 'get',
    });
  },

  editPlantInfo(number) {
    return Axios({
      url: `/plant/${number}`,
      method: 'put',
    });
  },

  myPlant() {
    return Axios({
      url: '/my-plant',
      method: 'get',
    });
  },

  postLog(memo, name, nickname, watered) {
    return Axios({
      url: '/log',
      method: 'post',
      data: { memo, name, nickname, watered },
    });
  },

  viewSingleLog() {
    return Axios({
      url: '/log/id',
      method: 'get',
    });
  },

  postBoard(title, content, plant) {
    return Axios({
      url: '/board',
      method: 'post',
      data: { title, content, plant },
    });
  },

  editBoard(title, content) {
    return Axios({
      url: '/board/number',
      method: 'put',
      data: { title, content },
    });
  },

  //   viewSingleBoard(tags) {
  //      const queryString =
  //     return Axios({
  //       url: `/board?tag=${}`,
  //       method: 'get',
  //     });
  //   },

  /**
   * 댓글을 다는 API
   * @param {string} text 댓글
   * @param {number} number 댓글 번호
   * @returns Axios
   */
  postComment(text, number) {
    return Axios({
      url: `/board/${number}/comment`,
      method: 'post',
      data: { text },
    });
  },

  postChildComment(text, number) {
    return Axios({
      url: `/comment/${number}`,
      method: 'post',
      data: { text },
    });
  },

  /**
   * 키우는 식물을 등록하기
   * @param {number} number 식물의 고유 번호
   * @param {string} nickname 식물의 별칭
   * @returns Axios
   */
  enrollPlant(number, nickname) {
    return Axios({
      url: `/plant/${number}/enroll`,
      method: 'post',
      data: {
        nickname,
      },
    });
  },
};
