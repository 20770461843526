import styles from './loginPage.module.scss';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import back from '../../assets/back1.jpg';
import Gardeners from '../../service/gardeners';
import { login } from '../../redux/slice';
import { useDispatch } from 'react-redux';
import { setCookie } from '../../util/cookie';
import toast from 'react-hot-toast';

function LoginPage() {
  const navigate = useNavigate();
  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange',
  });
  const dispatch = useDispatch();

  const onSubmit = async (userInput) => {
    const { data } = await Gardeners.signIn(userInput.username, userInput.password);
    if (Object.prototype.hasOwnProperty.call(data, 'error')) {
      toast(data.error);
    }
    setCookie('token', data.token);
    dispatch(login(data.token));
    navigate('/');
  };

  return (
    <div className={styles.loginPage}>
      <div className={styles.contents}>
        <h1>Welcome Back !</h1>
        <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
          <p>Username</p>
          <input
            className={styles.textInput}
            placeholder="Username"
            {...register('username', {
              required: true,
            })}
          />
          <p>Password</p>
          <input
            className={styles.textInput}
            type="password"
            placeholder="Password"
            {...register('password', { required: true, minLength: 4 })}
          />
          <PrimaryButton type="submit" disabled={!formState.isValid}>
            Sign In
          </PrimaryButton>
        </form>
      </div>
      <div className={styles.blank}>
        <img src={back} className={styles.backImg} alt="backGround" />
      </div>
    </div>
  );
}

export default LoginPage;
