import styles from './slide.module.scss';
import waterDrop from '../../assets/waterDrop.png';
import stooky from '../../assets/stooky.png';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ko';

function Slide({ nickname, sort, fromday }) {
  dayjs.extend(relativeTime);
  dayjs('ko');
  const navigate = useNavigate();

  const dayLeft = dayjs(fromday).from(dayjs());
  console.log(dayLeft);

  const handleEnrollButton = () => {
    navigate('/registerPlant');
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.infoWrapper}>
        <div className={styles.plnatNameBox}>
          <p className={styles.plantName}>{sort}</p>
          <p className={styles.plantNickname}>{nickname}</p>
          <div className={styles.plantMemoBox}>
            <p className={styles.memoTitle}>MEMO</p>
            <p className={styles.memo}>- 잎에 물뿌려 줘야함</p>
          </div>
        </div>
        <p className={styles.plantDate}>입양시간: {dayLeft}</p>
        <div className={styles.waterComponent}>
          <div className={styles.plantWater}>
            <div className={styles.waterDrop}>
              <img src={waterDrop} alt="waterDrop" />
            </div>

            <h5>Next watering...</h5>
            <p className={styles.plantWater}>5일 후</p>
          </div>
        </div>

        <button className={styles.moreInfoButton} type="button" onClick={handleEnrollButton}>
          Enroll Plant +
        </button>
      </div>
      <div className={styles.imgWrapper}>
        <img src={stooky} className={styles.plantImg} alt="pic" />
      </div>
    </div>
  );
}

Slide.propTypes = {
  nickname: PropTypes.string,
  sort: PropTypes.string,
  fromday: PropTypes.string,
};

export default Slide;
