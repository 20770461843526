import water from '../../assets/water.png';
import PropTypes from 'prop-types';

function Water({ numOfWater }) {
  switch (numOfWater) {
    case 5:
      return (
        <div>
          <img src={water} alt="sun" />
          <img src={water} alt="sun" />
          <img src={water} alt="sun" />
          <img src={water} alt="sun" />
          <img src={water} alt="sun" />
        </div>
      );
    case 4:
      return (
        <div>
          <img src={water} alt="sun" />
          <img src={water} alt="sun" />
          <img src={water} alt="sun" />
          <img src={water} alt="sun" />
        </div>
      );
    case 3:
      return (
        <div>
          <img src={water} alt="sun" />
          <img src={water} alt="sun" />
          <img src={water} alt="sun" />
        </div>
      );
    case 2:
      return (
        <div>
          <img src={water} alt="sun" />
          <img src={water} alt="sun" />
        </div>
      );
    case 1:
      return (
        <div>
          <img src={water} alt="sun" />
        </div>
      );

    default:
      return <h1>해당 정보가 없습니다.</h1>;
  }
}

export default Water;

Water.propTypes = {
  numOfWater: PropTypes.number,
};
