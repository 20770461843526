import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import Gardeners from '../../service/gardeners';
import styles from './registerPlantPage.module.scss';

export default function RegisterPlant() {
  const navigate = useNavigate();
  const [sort, setSort] = useState('');
  const handleInput = (e) => {
    setSort(e.currentTarget.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await Gardeners.enrollPlant(1, sort);
    navigate('/');
  };

  return (
    <div className={styles.registerPlantPage}>
      <h1 className={styles.title}>내 식물 등록</h1>
      <p className={styles.description}>내 식물에 대해 얄려주세요!</p>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.input}>
          <p>식물 종류</p>
          <input type="text" onChange={handleInput} value={sort} />
        </div>
        <div className={styles.input}>
          <p>마지막으로 물 준 날</p>
          <input type="date" className={styles.calendar} />
        </div>
        <div className={styles.input}>
          <p>식물을 입양한 날짜</p>
          <input type="date" className={styles.calendar} />
        </div>
        <button type="submit">등록하기</button>
      </form>
    </div>
  );
}
