import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import styles from './app.module.scss';
import MainPage from './routes/MainPage';
import SignupPage from './routes/SignupPage';
import LoginPage from './routes/LoginPage';
import BoardPage from './routes/BoardPage';
import RegisterPlant from './routes/RegisterPlant';
import { Toaster } from 'react-hot-toast';
import useAuth from './hooks/useAuth';
import MyPlantPage from './routes/MyPlantPage';

function App() {
  useAuth();
  return (
    <div className={styles.wrapper}>
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/board" element={<BoardPage />} />
          <Route path="/registerPlant" element={<RegisterPlant />} />
          <Route path="myPlant" element={<MyPlantPage />} />
        </Routes>
      </Router>
      <Toaster />
    </div>
  );
}

export default App;
