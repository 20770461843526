import styles from './boardPage.module.scss';
import List from '../../components/ListItem';
import { useNavigate } from 'react-router-dom';

import Logo from '../../assets/pngs/logo.png';

function BoardPage() {
  const navigate = useNavigate();
  return (
    <div className={styles.boardPage}>
      <div className={styles.topButton}>
        <img src={Logo} className={styles.logoImg} alt="logo" />
        <button
          className={styles.menuButton}
          type="button"
          onClick={() => {
            navigate('/');
          }}
        >
          Home
        </button>
        <button
          className={styles.menuButton}
          type="button"
          onClick={() => {
            navigate('/board');
          }}
        >
          Board
        </button>
        <button
          className={styles.menuButton}
          type="button"
          onClick={() => {
            navigate('/myplant');
          }}
        >
          MyPlant
        </button>
        <button
          className={styles.logOutButton}
          type="button"
          onClick={() => {
            navigate('/');
          }}
        >
          Log Out
        </button>
      </div>
      <div className={styles.search}>
        <List />
        <List />
        <List />
      </div>
    </div>
  );
}

export default BoardPage;
