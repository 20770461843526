import styles from './myPlantPage.module.scss';
import pepe from '../../assets/pngs/plea.png';

export default function MyPlantPage() {
  return (
    <div className={styles.myPlantPage}>
      <div className={styles.wrraper}>
        <div className={styles.top}>
          <h1 className={styles.title}>가드닝 스케줄</h1>
        </div>
        <div className={styles.contents}>
          <div className={styles.plantInfo}>
            <p className={styles.plantName}>똥글이</p>
            <p className={styles.plantKind}>필레아 페페</p>
          </div>
          <div className={styles.plantImg}>
            <img src={pepe} className={styles.pepe} alt="plant" />
          </div>
          <div className={styles.water}>
            <p className={styles.waterDay}>마지막으로 물 준 날짜</p>
            <p className={styles.lastWaterDay}>5일 전</p>
            <p className={styles.waterDay}>다음 물 줄 날짜</p>
            <p className={styles.nextWaterDay}>1일 후</p>
          </div>
          <div className={styles.memo}>
            <p className={styles.memoTitle}>부가 내용</p>
            <div className={styles.memoInput} />
          </div>
        </div>
      </div>
    </div>
  );
}
