import PropTypes from 'prop-types';
import styles from './button.module.scss';

export default function Button({ children, type, onClick, disabled }) {
  if (type === 'submit')
    return (
      <button type="submit" className={styles.button} disabled={disabled}>
        {children}
      </button>
    );
  return (
    <button type="button" className={styles.button} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'button']).isRequired,
};
