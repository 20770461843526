import styles from './listItem.module.scss';

function ListItem() {
  return (
    <div className={styles.listItem}>
      <div className={styles.title}>
        <div className={styles.userPic} />
        <div>
          <h1>안녕하세요 몬스테라 1년차 식집사입니다~</h1>
          <h2>fjies34</h2>
        </div>
      </div>
      <p className={styles.post}>
        수경재배하던 몬스테라가 갑자기 잎이 노랗게 변해서 저와 같은 경험 하신 분 계신가요 ㅠ ? 이런 경우는 대부분
        물꽂이에서 흙에다 키우면 다시 건강해진다고 하는데 저는 수경재배를 계속하고 싶어서요 ㅠ 고수 식집사님들의 의견을
        묻습니당
      </p>
      <div className={styles.comment}>5 Comments</div>
    </div>
  );
}

export default ListItem;
