import styles from './signupPage.module.scss';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Gardeners from '../../service/gardeners';
import toast from 'react-hot-toast';
import PrimaryButton from '../../components/Buttons/PrimaryButton';

import back from '../../assets/back2.jpg';

function SignupPage() {
  const navigate = useNavigate();
  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange',
  });

  const onSubmit = async (input) => {
    const { data } = await Gardeners.signUp(input.username, input.password);
    if (Object.prototype.hasOwnProperty.call(data, 'error')) {
      toast(data.error);
      return;
    }
    navigate('/login');
  };
  return (
    <div className={styles.signupPage}>
      <div className={styles.contents}>
        <h1>Welcome !</h1>
        <form className={styles.signupForm} onSubmit={handleSubmit(onSubmit)}>
          <div>
            <p>Username</p>
            <input
              className={styles.textInput}
              type="text"
              placeholder="Username"
              {...register('username', {
                require: true,
              })}
            />
          </div>
          <div>
            <p>Password</p>
            <input
              className={styles.textInput}
              placeholder="******"
              type="password"
              {...register('password', { required: true, minLength: 8 })}
            />
          </div>
          <PrimaryButton type="submit" disabled={!formState.isValid}>
            Sign up
          </PrimaryButton>
        </form>
        <div className={styles.signIn}>
          <h2>이미 계정이 존재하나요?</h2>
          <button
            type="button"
            className={styles.signinBtn}
            onClick={() => {
              navigate('/login');
            }}
          >
            Sign in
          </button>
        </div>
      </div>
      <div className={styles.blank}>
        <img src={back} className={styles.backImg} alt="backGround" />
      </div>
    </div>
  );
}

export default SignupPage;
