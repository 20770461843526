import { useSelector, useDispatch } from 'react-redux';
import { login } from '../redux/slice';
import { getCookie } from '../util/cookie';

const useAuth = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const token = getCookie('token');

  if (token && isAuthenticated) return;

  if (token && !isAuthenticated) dispatch(login(token));
};

export default useAuth;
