import PropTypes from 'prop-types';
import styles from './rank.module.scss';
import Sun from '../Sun';
import Water from '../Water';
import PlantPic from '../../assets/tablePalm.png';

function Rank({ plantName, plantNameEng, numOfSun, numOfWater, index, ondo, talk, nanedo }) {
  const wrapperStyle = index % 2 === 0 ? `${styles.wrapper} ${styles.even}` : styles.wrapper;

  return (
    <li className={wrapperStyle}>
      <div className={styles.plantImg}>
        <img src={PlantPic} alt="temp" />
      </div>
      <div className={styles.contentsWrapper}>
        <div className={styles.plantName}>
          <p className={styles.plantNameKor}>{plantName}</p>
          <p className={styles.plantNameEng}>{plantNameEng}</p>
        </div>
        <div className={styles.plantInfo}>
          <span>
            <p className={styles.black}>꽃말</p>
            <p className={styles.gray}>{talk}</p>
          </span>
          <span>
            <p className={styles.black}>물 주기</p>
            <div className={styles.gray}>
              <Water numOfWater={numOfWater} />
            </div>
          </span>
          <span>
            <p className={styles.black}>햇볕</p>
            <div className={styles.gray}>
              <Sun numOfSun={numOfSun} />
            </div>
          </span>
          <span>
            <p className={styles.black}>난이도</p>
            <p className={styles.gray}>{nanedo}</p>
          </span>
          <span>
            <p className={styles.black}>온도</p>
            <p className={styles.gray}>{ondo}</p>
          </span>
        </div>
      </div>
    </li>
  );
}

Rank.propTypes = {
  plantName: PropTypes.string,
  plantNameEng: PropTypes.string,
  numOfSun: PropTypes.number,
  numOfWater: PropTypes.number,
  index: PropTypes.number,
  nanedo: PropTypes.string,
  talk: PropTypes.string,
  ondo: PropTypes.string,
};

export default Rank;
