/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Gardeners from '../../service/gardeners';
import { useState, useEffect, useCallback } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Plant from '../Slide';

import styles from './carousel.module.scss';

function SampleNextArrow({ className, style, onClick }) {
  return <div className={className} style={{ ...style, display: 'block', color: '#000' }} onClick={onClick} />;
}
SampleNextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.string,
  onClick: PropTypes.func,
};

const plants = [];

const makePlantArr = (recursiveData) => {
  if (!recursiveData) return;
  plants.push({ nickname: recursiveData.nickname, fromDay: recursiveData.fromDay });
  makePlantArr(recursiveData.next);
};

export default function Carousel() {
  const [plantData, setPlantData] = useState();

  useEffect(() => {
    async function getData() {
      const { data } = await Gardeners.myPlant();
      setPlantData(data[0].head);
    }
    getData();
  }, []);

  useEffect(() => {
    makePlantArr(plantData);
  }, [plantData]);

  const settings = {
    className: `center`,
    centerMode: true,
    dots: true,
    infinite: true,
    adaptiveHeight: true,
    centerPadding: '60px',
    slidesToShow: 1,
    arrows: false,
    speed: 500,
  };
  return (
    <div className={styles.container}>
      <Slider {...settings} className={styles.slider}>
        {plants &&
          plants.map((plantItem) => {
            console.log(plantItem);
            return (
              <div className={styles.slides}>
                <Plant nickname={plantItem.nickname} fromday={plantItem.fromDay} />
              </div>
            );
          })}
      </Slider>
    </div>
  );
}
